import { Injectable } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { of } from "rxjs";
import { FieldValueResolver } from "../../../common/field-value.resolver";


@Injectable()
export class ReferenceLookupService {
    constructor(private readonly fieldValueResolve: FieldValueResolver, private readonly route: ActivatedRoute) {}


    getSubReferences(field_id: any) {
        return of(this.fieldValueResolve.getSubReferences(field_id));
    }

    getReferences(field_id: any) {
        const field = this.fieldValueResolve.references.fieldMap[field_id];
        return of(
            Object.values(this.fieldValueResolve.references.customReferenceMap?.[field.reference_type_id] ?? {}).map((ref: any) => ({
                value: ref.id,
                label: ref.name
            })).sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true } ))
        )
    }

    getIntakeForms() {
        return of(
            Object.values(this.fieldValueResolve.references.intakeMap ?? {}).map((ref: any) => ({
                value: ref.id,
                label: ref.name
            })).sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true } ))
        )
    }

    getFields() {
        return of(
            Object.values(this.fieldValueResolve.references.fieldMap ?? {}).map((ref: any) => ({
                value: ref.id,
                label: ref.name
            }))
            .sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true } ))
        )
    }

}